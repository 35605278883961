import {
    Datagrid,
    EditButton,
    EmailField,
    List,
    Show,
    TextField,
    SimpleShowLayout,
    ShowButton,
    useRecordContext,
    useDataProvider,
    downloadCSV
} from "react-admin";
import React, {useEffect, useState} from "react";
import {Stack, Button} from "@mui/material";
import {Legend, Pie, PieChart} from "recharts";
import moment from "moment/moment";
import {unparse as convertToCSV} from 'papaparse/papaparse.min';

export function QuestionsList(props) {
    return <List {...props}>
        <Datagrid expand={<QuestionsShow/>}>
            <TextField label="ID" source="_id"/>
            <TextField label="Titel" source="title"/>
            <TextField label="Fragetyp" source="type"/>
            <TextField label="Erforderlich?" source="required"/>
            <ShowButton label="Ergebnisse"/>
            {/*<EditButton label="Bearbeiten"/>*/}
        </Datagrid>
    </List>
}

export function QuestionsShow(props) {
    return <Show {...props}>
        <SimpleShowLayout>
            <TextField label="ID" source="_id"/>
            <TextField label="Titel" source="title"/>
            <TextField label="Fragetyp" source="type"/>
            <TextField label="Erforderlich?" source="required"/>
            <AnswerVisualization/>
        </SimpleShowLayout>
    </Show>
}

const AnswerVisualization = (props) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [chartType, setChartType] = useState('pie');
    const [answers, setAnswers] = useState(null)
    if (!record) return null;

    useEffect(() => {
        dataProvider.getMany(`admin/surveys/answers`, {'for': record._id})
            .then(answers => {
                setAnswers(answers)
            })
            .catch(error => console.error(error))
    }, [record]);

    if (!answers) return <p>Loading the data for the visualizations.</p>;

    // Prepare data to make visualization
    let answersDf = [];
    let answersLabeled = [];
    switch (record.type) {
        case 'yesNo':
            answersLabeled = answers.data.map(a => {
                return {
                    _id: a._id,
                    answerText: a.answers[record._id] === 'yes' ? 'Ja' : 'Nein', ...a
                }
            })
            answersDf = answersLabeled.map(a => a.answerText)
            break;
        default:
            answersLabeled = answers.data.map(a => {
                return {
                    _id: a._id,
                    answerText: record.data[a.answers[record._id]], ...a
                }
            })
            answersDf = answersLabeled.map(a => a.answerText)
            break;
    }

    answersLabeled = answersLabeled.map(a => flattenObject(a))

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    for (let alpha = 80; alpha >= 40; alpha -= 20) {
        COLORS.push(...COLORS.map(color => color + alpha))
    }

    // get occurrences of same result
    const counts = Object.entries(
        answersDf.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
        }, {})
    ).map(([answer, count], index) => {
        return {
            answer,
            count,
            fill: COLORS[index % COLORS.length]
        }
    })

    const original = Object.values(record.data)

    counts.sort((a, b) => {
        return original.indexOf(a.answer) - original.indexOf(b.answer)
    })

    const exportAnswers = () => {
        let currentDate = moment().format("YYMMDDTHHmmss");

        const csv = convertToCSV({
            data: answersLabeled,
            fields: Object.keys(answersLabeled[0]).filter(a => !['__v', 'id'].includes(a))
                .filter(a => !a.startsWith('answers.'))
        });
        downloadCSV(csv, `${record._id}-export-answers-${currentDate}`); // download as 'posts.csv` file
    };

    return <Stack>
        <PieChart width={500} height={500}>
            <Pie data={counts} dataKey='count' nameKey='answer' label animationDuration={500}/>
            <Legend/>
        </PieChart>
        <Button onClick={exportAnswers}>Antworten exportieren (CSV)</Button>
    </Stack>
}

function flattenObject(obj, parentKey = '', result = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let propName = parentKey ? `${parentKey}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                flattenObject(obj[key], propName, result);
            } else {
                result[propName] = obj[key];
            }
        }
    }
    return result;
}