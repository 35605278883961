import React, {Component} from 'react';
import {
    ReferenceLine,
    BarChart,
    Legend,
    PieChart,
    Pie,
    Cell,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    ResponsiveContainer, CartesianGrid, XAxis, YAxis, Bar, LabelList,
    Tooltip as ChartTooltip
} from 'recharts';
import Card from '@mui/material/Card';
import WebAnalyticsTable from "./WebAnalyticsTable";
import authProvider from "../../utilities/authProvider";
import TimeOnConsentManagerTable from "./TimeOnConsentManagerTable";
import GaugeChart from "./GaugeChart";
import {
    Box,
    Button,
    CardHeader,
    CircularProgress,
    Drawer,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import {
    Assignment,
    Help,
    Visibility,
    VisibilityOff,
} from '@mui/icons-material'
import {getDocsFor} from "../../utilities/documentation";
import etrackerLogo from "../../assets/images/logo-etracker.png"
import awsLogo from "../../assets/images/logo-aws.png"

// Pie chart configuration
const COLORS = ['#d82727', '#6b0fb1', '#13ac87', '#74c91e'];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

// Arrays of objects summation reducer
/*
const summationReducer = (inputArray, value) => {
    return inputArray.reduce((accumulator, current) => accumulator + current[value], 0);
}
*/

// Layout styles
/*
const styles = {
    flex: {display: 'flex', flexFlow: 'wrap'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em', width: 'auto'},
    rightCol: {flex: 1, marginLeft: '1em', width: 'auto'},
    singleCol: {marginTop: '2em', marginBottom: '2em', flexShrink: 0, width: '100%'},
};
*/

const chartWrapperStyles = {
    width: 'auto',
    height: '250px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
};

class Dashboard extends Component {
    state = {
        analytics: {
            consentAcceptance: [],
            consentDistribution: [],
            meanClientDelta: 0,
            medianClientDelta: 0,
            modeClientDelta: 0,
            bounceRate: 0,
            sessions: 0,
            uniqueVisitors: 0,
            pageImpressions: 0,
            pagesPerSession: 0,
            avgSessionDuration: 0,
            isLoaded: false
        },
        categories: [],
        health: null,
        phases: {},
        drawerOpen: false,
        enableTrackingFactor: true,
        enableWebAnalytics: true,
        includeTrackingFactor: true,
    };

    toggleDrawer = (state) => () => {
        this.setState({
            drawerOpen: state,
        });
    };

    toggleTrackingFactorInclusion = () => {
        this.setState({
            includeTrackingFactor: !this.state.includeTrackingFactor,
        });
    };

    componentDidMount() {

        // Check response status for each request and call AUTH_LOGOUT when not OK
        function fetchJSON(...args) {
            return fetch(...args)
                .then(response => {
                    if (!response.ok) {
                        // Allow the health endpoint to fail without logout, as some instances run on EC2 and not Elastic beanstalk
                        if(response.url.includes("health") && response.status === 500){
                            console.log("Failed response: ", response)
                            return null;
                        } else {
                            authProvider('AUTH_LOGOUT');
                        }
                    } else {
                        return response.json();
                    }
                })
        }

        function fetchJSONAndTransform(...args) {
            return fetch(...args)
                .then(response => response.json())
                .then(responseData => {
                    const transformedData = responseData.map(entry => {
                        const transformedEntry = {
                            creationDate: entry.creationDate,
                            inReview: entry.inReview,
                            approved: entry.approved,
                            denied: entry.denied
                        };
                        entry.categories.forEach(category => {
                            transformedEntry[category.categoryName] = category.categoryValue;
                        });
                        return transformedEntry;
                    });
                    return transformedData;
                    })
                .catch(error => {
                    console.error(error);
                })
        }

        async function fetchDashboardData() {
            const query = '?filter={}&range=[0,9999]&sort={"creationDate":"DESC"}'
            const BEARER_TOKEN = localStorage.getItem('idToken')
            const headersBigfootAPI = {
                'Authorization': 'Bearer ' + BEARER_TOKEN,
                'Content-Type': 'application/json',
            }

            const [optionsResponse, phasesResponse, projectsResponse, healthResponse, analyticsResponse, zuwachsResponse] = await Promise.all([
                fetchJSON(`${process.env.REACT_APP_API_URL}/options`, {headers: headersBigfootAPI}),
                fetchJSON(`${process.env.REACT_APP_API_URL}/phases`, {headers: headersBigfootAPI}),
                fetchJSON(`${process.env.REACT_APP_API_URL}/admin/projects${query}`, {headers: headersBigfootAPI}),
                fetchJSON(`${process.env.REACT_APP_API_URL}/health`, {headers: headersBigfootAPI}),
                fetchJSON(`${process.env.REACT_APP_API_URL}/analytics`, {headers: headersBigfootAPI}),
                fetchJSONAndTransform(`${process.env.REACT_APP_API_URL}/analytics/projects`, {headers: headersBigfootAPI}),
            ]);

            const options = await optionsResponse;
            const analytics = await analyticsResponse;
            const phases = await phasesResponse;
            const projects = await projectsResponse;
            const health = await healthResponse;
            const zuwachs = await zuwachsResponse;

            return [options, analytics, phases, projects, health, zuwachs]
        }

        fetchDashboardData()
            .then(([options, analytics, phases, projects, health, zuwachs]) => {
                    this.setState({
                        categories: options[0].projectForm.projectCategory && options[0].projectForm.projectCategory.elementConfig.options,
                        analytics: analytics[0],
                        phases: phases,
                        projectsTotal: projects.length,
                        health: health,
                        zuwachs: zuwachs,
                        isLoaded: true
                    })
                }
            ).catch(err => {
            console.log(err);
            throw new Error(err);
        })
    }

    render() {
        return (
            <>
                <Drawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}>
                    <div style={{maxWidth: '640px', padding: '1rem'}}>{getDocsFor('changelog')}</div>
                </Drawer>
                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Box style={{width: 'calc(50% - 2rem)', padding: '1rem'}}>
                        <h4>Cactus Capybara (0.6.0) Highlights</h4>
                        {getDocsFor('changelogHighlights')}
                        <Button
                            variant="contained"
                            style={{backgroundColor: '#74c91e'}}
                            startIcon={<Assignment/>}
                            onClick={this.toggleDrawer(true)}>
                            Changelog
                        </Button>
                    </Box>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(33.3% - 2rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Teilnehmerzahlen"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Zahl der freigeschalteten Teilnehmer vs. Zielwert"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer>
                                    <GaugeChart
                                        targetValue={process.env.REACT_APP_TARGET_PROJECTS} // Todo: Get targeted no. of projects from database
                                        currentValue={this.state.projectsTotal}/>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(33.3% - 2rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Teilnehmer-Referrer"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Wie haben wir alle bisherigen Teilnehmer erreicht?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer>
                                    <BarChart
                                        width={'auto'}
                                        height={200}
                                        data={this.state.analytics.referrers}
                                        layout="vertical"
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <ChartTooltip/>
                                        <XAxis type="number" orientation="top" stroke="#74c91e"/>
                                        <YAxis type="category" dataKey="name" axisLine={false} dx={-5} tick={false}
                                               tickLine={false}
                                               style={{fill: "#74c91e"}}/>
                                        <Bar background dataKey="referrerSum" fill="#74c91e" barSize={{height: 26}}>
                                            <LabelList dataKey="name" position="insideRight"
                                                       style={{fill: "white", fontSize: '12px'}}/>
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(33.3% - 2rem)', padding: '1rem'}}>
                        <CardHeader
                            action={
                                <>
                                    <Tooltip title={getDocsFor('includeTrackingFactor')}>
                                        <IconButton onClick={this.toggleTrackingFactorInclusion}>
                                            {this.state.includeTrackingFactor ?
                                                <Visibility color="#cc0000"/>
                                                :
                                                <VisibilityOff color="#cc0000"/>
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={getDocsFor('reachFactor')}>
                                        <IconButton aria-label="help">
                                            <Help color="#cc0000"/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            title="Reichweitenfaktor"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Wieviele Nutzer generiert ein einzelner Teilnehmer im Durchschnitt?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={{...chartWrapperStyles, flexwettbewerbeDirection: 'column'}}>
                            {this.state.enableTrackingFactor ?
                                this.state.isLoaded ?
                                    <>
                                        <h1 style={{fontSize: '5rem'}}>
                                            {this.state.includeTrackingFactor ?
                                                (this.state.analytics.webAnalyticsAll.uniqueVisitors / this.state.projectsTotal).toFixed(1) :
                                                (this.state.analytics.webAnalyticsAll.uniqueVisitors / this.state.projectsTotal * this.state.analytics.trackingFactor).toFixed(1)
                                            }
                                        </h1>
                                        <Box color="text.secondary">
                                            ({this.state.includeTrackingFactor ?
                                            this.state.analytics.webAnalyticsAll.uniqueVisitors + '/' + this.state.projectsTotal :
                                            (this.state.analytics.webAnalyticsAll.uniqueVisitors * this.state.analytics.trackingFactor).toFixed(1) + '/' + this.state.projectsTotal
                                        })
                                        </Box>
                                    </>
                                    : <CircularProgress/>
                                : <h4 style={{color: 'rgba(0, 0, 0, 0.54)'}}>Keine Daten verfügbar</h4>}
                        </div>
                    </Card>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(100% - 4rem)', padding: '1rem'}}>
                        <h4>Bewerbungszuwachs im Tagesverlauf</h4>
                        <div className="area-chart-wrapper" style={{...chartWrapperStyles, height: '400px'}}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.zuwachs}
                                        margin={{
                                            top: 20,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="creationDate" />
                                        <YAxis />
                                        <ChartTooltip/>
                                        <Legend />
                                        <Bar dataKey="denied" stackId="a" name="Abgelehnt" fill="#000000" />
                                        {this.state.categories ? this.state.categories.map((category, index) => {
                                            return <Bar dataKey={category.value} name={category.displayValue} stackId="a" fill={COLORS[index]} />
                                        }) : <Bar dataKey="approved" stackId="a" name="Freigeschaltet" fill="#74c91e" />}
                                        <Bar dataKey="inReview" stackId="a" name="In Warteschleife" fill="#cccccc" />
                                        <ReferenceLine x={this.state.phases.campaignLaunch.split('T')[0]} stroke="red" label="Launch"  />
                                        {this.state.phases.type.map((phase, index) => {
                                            return <ReferenceLine
                                                x={phase.phaseStart.split('T')[0]}
                                                stroke="red"
                                                label={ index === 0 ? "Abstimmungsstart" : "Finalstart"} />
                                        })}
                                    </BarChart>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>
                </div>


                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(33.3% - 2rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Kampagnenbudgetstatus"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Welcher Anteil des verfügbaren Budgets wurde bereits verbraucht?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer>
                                    <GaugeChart
                                        targetValue={this.state.analytics.budgetAnalytics.budgetAvailable} // Todo: Get targeted no. of projects from database
                                        currentValue={Math.round(this.state.analytics.budgetAnalytics.budgetDistribution[0].amount)}
                                        reverse/>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(66.6% - 3rem)', padding: '1rem'}}>
                        <CardHeader
                            action={
                                <Tooltip title={getDocsFor('acquisitionCosts')}>
                                    <IconButton aria-label="help">
                                        <Help color="#cc0000"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            title="Akquisitionskosten"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Was kostet die Akquise eines Teilnehmenden?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell/>
                                                <TableCell style={{fontWeight: 700}}
                                                           align="right">Post-Seeding</TableCell>
                                                <TableCell style={{fontWeight: 700}}
                                                           align="right">E-Mail-Seeding</TableCell>
                                                <TableCell style={{fontWeight: 700}}
                                                           align="right">Adressrecherche</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Gesamtkosten</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.postalSeedingCosts) + ' Euro'}</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.emailSeedingCosts) + ' Euro'}</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.addressResearchCosts) + ' Euro'}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell>Kosten je Teilnehmer ({this.state.projectsTotal}) je
                                                    Leistung</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.postalSeedingCosts / this.state.projectsTotal) + ' Euro'}</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.emailSeedingCosts / this.state.projectsTotal) + ' Euro'}</TableCell>
                                                <TableCell
                                                    align="right">{'≈ ' + Math.round(this.state.analytics.budgetAnalytics.addressResearchCosts / this.state.projectsTotal) + ' Euro'}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{fontWeight: 700}}>Kosten je Teilnehmer
                                                    ({this.state.projectsTotal})</TableCell>
                                                <TableCell style={{fontWeight: 700}} align="center"
                                                           colSpan={3}>{'≈ ' + Math.round((this.state.analytics.budgetAnalytics.postalSeedingCosts + this.state.analytics.budgetAnalytics.emailSeedingCosts + this.state.analytics.budgetAnalytics.addressResearchCosts) / this.state.projectsTotal) + ' Euro'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(100% - 4rem)', padding: '1rem'}}>
                        <h4>Webanalyse-KPIs bereitgestellt von <img style={{marginBottom: '10px'}} src={etrackerLogo} alt="etracker Logo"/>
                        </h4>
                        <div className="area-chart-wrapper" style={{...chartWrapperStyles, height: 'auto'}}>
                            {this.state.isLoaded ?
                                <WebAnalyticsTable
                                    trackingFactor={this.state.analytics.trackingFactor}
                                    campaignLaunched={this.state.phases.campaignLaunched}
                                    webAnalyticsData={[this.state.analytics.webAnalyticsSevenDays, this.state.analytics.webAnalyticsAll]}/>
                                : <CircularProgress/>}
                        </div>
                    </Card>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(25% - 1.75rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Consent-Optionen Details"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Verteilung der CMT-Optionen"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer>
                                    <RadarChart cx="50%" cy="50%" outerRadius="80%"
                                                data={this.state.analytics.consentDistribution}>
                                        <PolarGrid/>
                                        <PolarAngleAxis dataKey="consentType"/>
                                        <PolarRadiusAxis/>
                                        <Radar name="Consent Radar" dataKey="consentSum" stroke="#39c91e" fill="#74c91e"
                                               fillOpacity={0.6}/>
                                        <ChartTooltip/>
                                    </RadarChart>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(25% - 1.75rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Zeit auf Consent-Manager"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Wie lange war das CMT geöffnet?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <TimeOnConsentManagerTable
                                    meanClientDelta={this.state.analytics.meanClientDelta}
                                    medianClientDelta={this.state.analytics.medianClientDelta}
                                    modeClientDelta={this.state.analytics.modeClientDelta}/>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(25% - 1.75rem)', padding: '1rem'}}>
                        <CardHeader
                            title="Consent-Optionsverteilung"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Alles akzeptiert vs. Auswahl"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded ?
                                <ResponsiveContainer>
                                    <PieChart>
                                        <Pie
                                            data={this.state.analytics.consentAcceptance}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="consentSum">
                                            {this.state.analytics.consentAcceptance.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[(index + 2) % COLORS.length]}/>
                                            ))}
                                        </Pie>
                                        <Legend/>
                                        <ChartTooltip/>
                                    </PieChart>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(25% - 1.75rem)', padding: '1rem'}}>
                        <CardHeader
                            action={
                                <Tooltip title={getDocsFor('trackingFactor')}>
                                    <IconButton aria-label="help">
                                        <Help color="#cc0000"/>
                                    </IconButton>
                                </Tooltip>
                            }
                            title="Tracking-Faktor"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Zur Webanalyse-KPI-Hochrechnung"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={{...chartWrapperStyles, flexDirection: 'column'}}>
                            {this.state.enableTrackingFactor ?
                                this.state.isLoaded ?
                                    <>
                                        <h1 style={{fontSize: '5rem'}}>
                                            {this.state.analytics.trackingFactor.toFixed(1)}
                                        </h1>
                                        <Box
                                            color="text.secondary">({this.state.analytics.consentTotal} / {this.state.analytics.webAnalyticsAll.uniqueVisitors})</Box>
                                    </>
                                    : <CircularProgress/>
                                : <h4 style={{color: 'rgba(0, 0, 0, 0.54)'}}>Keine Daten verfügbar</h4>}
                        </div>
                    </Card>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem'}}>
                    <Card style={{width: 'calc(33% - 2rem)', padding: '1rem'}}>
                        <CardHeader
                            title="CPU-Auslastung der Backend-Instanz"
                            titleTypographyProps={{variant: 'body1'}}
                            subheader="Wie stark sind die Backend-Instanzen ausgelastet?"
                            subheaderTypographyProps={{variant: 'subtitle2'}}
                            style={{padding: '0 0 1rem 0'}}/>
                        <div className="area-chart-wrapper" style={chartWrapperStyles}>
                            {this.state.isLoaded && this.state.health ?
                                <ResponsiveContainer>
                                    <BarChart
                                        height={200}
                                        data={this.state.health.details.CPUUtilization}
                                        layout="vertical">
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <ChartTooltip/>
                                        <XAxis type="number" orientation="top" stroke="#74c91e"/>
                                        <YAxis type="category" dataKey="name" axisLine={false} dx={-5} tick={true}
                                               tickLine={false}
                                               style={{fill: "#74c91e"}}/>
                                        <Bar background dataKey="value" fill="#74c91e" barSize={{height: 26}}/>
                                    </BarChart>
                                </ResponsiveContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>

                    <Card style={{width: 'calc(66% - 2rem)', padding: '1rem'}}>
                        <h4>Instanz-KPIs bereitgestellt von <img style={{marginBottom: '10px'}} src={awsLogo} alt="aws Logo"/></h4>
                        <div className="area-chart-wrapper" style={{...chartWrapperStyles, height: 'auto'}}>
                            {this.state.isLoaded && this.state.health ?
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{fontWeight: 700}}>Instanz-Id</TableCell>
                                                <TableCell style={{fontWeight: 700}}>Verfügbarkeitszone</TableCell>
                                                <TableCell style={{fontWeight: 700}}>
                                                    Zustand
                                                    <Tooltip title={getDocsFor('AWSInstanceState')}>
                                                        <IconButton size="small">
                                                            <Help fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell style={{fontWeight: 700}}>Typ</TableCell>
                                                <TableCell style={{fontWeight: 700}}>Laufzeit App</TableCell>
                                                <TableCell style={{fontWeight: 700}}>Laufzeit Instanz</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{this.state.health.details.InstanceId}</TableCell>
                                                <TableCell>{this.state.health.details.AvailabilityZone}</TableCell>
                                                <TableCell
                                                    style={{color: this.state.health.details.instanceHealthColor}}>{this.state.health.details.HealthStatus}</TableCell>
                                                <TableCell>{this.state.health.details.instanceType}</TableCell>
                                                <TableCell>{Math.round(this.state.health.details.uptime[0].observedValue / 60)} Minuten</TableCell>
                                                <TableCell>{Math.round(this.state.health.details.uptime[1].observedValue / 60)} Minuten</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                : <CircularProgress/>}
                        </div>
                    </Card>
                </div>
            </>
        );
    }
}

export default Dashboard;
